<ng-container>
    <div class="loading">
        <div class="icon">
            <span class="icon-clock"></span>
        </div>
        <p>
            {{ 'management.general.loading' | translate }}
        </p>
    </div>
</ng-container>
