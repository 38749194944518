<div *ngIf="penalties | async as penalties">
    <!-- Page title -->
    <h3>{{'profile.penalties.title' | translate}}</h3>
    <hr>
    <!-- Show faq about penalty points -->
    <div class="row">
        <div>
            <markdown *ngIf="currentLanguage() | async as currlan" src="assets/md/information-penalty.{{ currlan }}.md"></markdown>
        </div>
        <app-penalty-table [penalties]="penalties" [showDesignee]="false" [showTitle]="false"></app-penalty-table>
    </div>
</div>
