<!-- Teaser Underlined -->
<li class="teaser   teaser--underlined ">
    <article class="teaser-content">
        <div class="content__second">
            <h3>
                {{ location.name }}
            </h3>

            <div class="entry">
                <i class="icon-clock" aria-hidden="true"></i>
                <span>{{location.currentTimeslot | formatStatus | async }}</span>
            </div>
            <div class="entry">
                <i class="icon-user" aria-hidden="true"></i> {{location.numberOfSeats}}
            </div>
            <div class="entry">
                <i class="icon-home" aria-hidden="true"></i>
                {{location.building.name}}<br/>{{location.building.address}}
            </div>
            <div class="entry">
                <i class="icon-calendar" aria-hidden="true"></i>
                {{nextReservableFrom ? (nextReservableFrom.toDate() | date:'short') :
                ('general.notAvailableAbbreviation' | translate)}}
            </div>

            <a routerLink="/dashboard/{{location.locationId}}" class="read-more standalone-link"
               title="{{'dashboard.details' | translate}}">
                {{'dashboard.details' | translate}}
            </a>
        </div>

        <div class="content__first">
            <div class="figure-wrapper">
                <div class="item-image">
                    <div class="locationStatusBar"
                         [classList]="'locationStatusBar ' + locationStatusColorClass()"></div>
                    <figure class="teaser--underlined">
                        <div class="image-wrapper" data-ratio="8:5">
                            <img *ngIf="!imageUrlErrorOccurred && location.imageUrl" src="{{location.imageUrl}}"
                                 class="teaser--underlined" alt="Image for {{location.name}}"
                                 (error)="handleImageError()">
                            <img *ngIf="imageUrlErrorOccurred || !location.imageUrl" [src]="altImageUrl"
                                 alt="Image of {{location.name}}" class="teaser--underlined">
                        </div>
                    </figure>
                </div>
                <div class="accolade-inverse bottom-left"></div>
            </div>


        </div>
    </article>
    <!-- <a href="#" class="teaser-overlay-link" tabindex="-1" aria-hidden="true">Teasers can also be underlined</a> -->
</li>
