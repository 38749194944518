<ng-container *ngIf="userObs$ | async as user">
    <div class="row justify-content-center">
        <!-- Menu for /management -->
        <div class="col-12 col-lg-3">
            <aside>
                <section class="highlight highlight--top">
                    <div class="highlight__inner px-4">
                        <h2 class="mt-0">
                            {{ 'management.aside.title' | translate }}
                        </h2>
                        <div class="d-flex flex-column gap-3 align-items-between">
                            <a routerLink="./locations" *ngIf="user.isAuthority()">
                                {{'management.aside.locations' | translate}}
                            </a>
                            <a routerLink="./buildings" *ngIf="user.isAuthority()">
                                {{'management.aside.buildings' | translate}}
                            </a>
                            <a routerLink="./authorities" *ngIf="user.isAdmin()">
                                {{'management.aside.authorities' | translate}}
                            </a>
                            <a routerLink="./users" *ngIf="user.isAdmin()">
                                {{'management.aside.users' | translate}}
                            </a>
                            <a routerLink="./faq" *ngIf="user.isAdmin()">
                                {{'management.aside.faq' | translate}}
                            </a>
                            <a routerLink="./tags" *ngIf="user.isAdmin()">
                                {{'management.aside.tags' | translate}}
                            </a>
                            <a routerLink="./admins" *ngIf="user.isAdmin()">
                                {{'management.aside.admins' | translate}}
                            </a>
                            <a routerLink="./actionlog" *ngIf="user.isAdmin()">
                                {{'management.aside.actionlog' | translate}}
                            </a>
                            <a routerLink="./stats" *ngIf="user.isAdmin()">
                                {{'management.aside.stats' | translate}}
                            </a>
                        </div>
                    </div>
                </section>
            </aside>
        </div>
        <div class="col-12 col-lg-9 mt-4">
            <article>
                <section class="messages messages--error" *ngIf="showStagingWarning">
                    <div markdown src="assets/md/staging-warning.nl.md">

                    </div>
                </section>
                <router-outlet>

                </router-outlet>
            </article>
        </div>
    </div>
</ng-container>
