<ng-container>
    <div class="faq-sidebar-item px-2 py-1 rounded d-flex align-items-center justify-content-between fs-7 fw-semibold my-2"  (click)="toggled = !toggled">
        <div class="d-flex align-items-center gap-2">
            <span class="faq-sidebar-item-index">{{ index + 1}}</span>
            <span>{{ category.name.translations[locale] }}</span>
        </div>
        <span class="icon-chevron-down fs-5"></span>
    </div>
    <div class="faq-sidebar-dropdown ps-2" *ngIf="toggled === true">
        <a [routerLink]="['/faq', item.id]" class="faq-sidebar-item px-2 py-1 rounded" *ngFor="let item of category.items">
            <div class="d-flex gap-2">
                <span class="icon-arrow-right"></span>
                <span>{{ item.title.translations[locale] }}</span>
            </div>
        </a>
    </div>
</ng-container>
